.createToggle {
  padding: 24px;
  display: grid;
  gap: 24px;
  h5 {
    font-size: 28px;
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 30px;
  }
  ul {
    list-style: auto;
    padding-left: 24px;
    li {
      font-size: 24px;
      color: var(--neutral-medium);
      font-weight: 300;
      line-height: 30px;
    }
  }
  button {
    justify-content: center;
  }
}
