.toggleContainer {
  background: var(--neutral-white);
  border-radius: 16px;
  padding: 24px;
  .flex {
    display: flex;
    gap: 24px;
    margin: 0 0 24px;
    & > div {
      flex: 1;
    }
  }
  :global .react-tabs__tab-panel {
    padding-top: 16px;
  }
  .property {
    display: flex;
    align-items: center;
    gap: 16px;
    img {
      width: 64px;
    }
    h5 {
      font-size: 16px;
      color: var(--neutral-dark);
      font-weight: 500;
      line-height: 24px;
    }
  }
  .action {
    display: flex;
    align-items: center;
    gap: 16px;
    .delete {
      padding: 8px 12px;
      span {
        margin: 0;
      }
      svg {
        path {
          stroke: var(--neutral-medium);
        }
      }
    }
  }
}
