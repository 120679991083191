.workspace {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  p {
    font-size: 20px;
    color: var(--neutral-medium);
    font-weight: 500;
    line-height: 26px;
  }
  .upload {
    display: flex;
    gap: 16px;
    & > div span.uploadBtn {
      display: flex;
    }
  }
  .btn {
    display: flex;
    gap: 16px;
    button {
      flex: 1;
      justify-content: center;
    }
  }
}
