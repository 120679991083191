.ChannleMemberholder{ 
    display: flex;
    gap:10px;
    & .profilePic{
        width:48px;
        height:48px;
        border-radius:100%;
        overflow: hidden;
        background:var(--neutral-medium);
        & img{
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }
    & .ChannleMemberMedia{
        display: flex;
        justify-content: space-between;
        align-items:center;
        flex: 1;
        & h4{
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; 
        }
        & span{
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color:var(--neutral-medium);
        }
        & button{
            background:var(--neutral-light);
            height:34px;
            color:var(--primary-color);
            padding: 8px 12px;
        }
        & .buttonHolder{
            display: flex;
            align-items:center;
            gap:10px;
        }
    }
     
    
} 