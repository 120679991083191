.inviteUserModal {
  padding: 24px;
  background: #fff;
  border-radius: 16px;

  h2 {
    font-size: 28px;
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 30px;
  }
  .inviteuserBtns{
    padding: 2opx;
    display: flex;
    gap:20px;
    .inviteBtn{
      padding: 10px;
      width:20rem;
    }
  }
}
