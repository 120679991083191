.editProperty {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  .btn {
    display: grid;
    gap: 24px;
    width: 100%;
    .flex {
      flex-wrap: nowrap;
      align-items: flex-end;
      justify-content: space-between;
      gap: 24px;
    }
  }
  .button {
    display: flex;
    gap: 16px;
    width: 100%;
    button {
      flex: 1;
      justify-content: center;
    }
  }
}
