.content {
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: var(--primary-color);
    padding: 0 0 8px;
    line-height: 48px;
  }
  p {
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 26px;
  }
}
.stepper {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0;
  padding-bottom: 11px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: none;
}
