.wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
.profileForm {
  display: flex;
  gap: 24px;
}

.profileContainer {
  background: var(--neutral-white);
  padding: 24px;
  border-radius: 16px;
  flex: 1;
  grid-column: span 8;
}

.upgrade {
  grid-column: span 4;
}

.profilePhoto {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
input[name="userName"] {
  pointer-events: none;
  color:#14192F80;
  font-weight:500;
}

.userForm {
  color: #000;
  width: 100%;
  :global .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 1024px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  form {
    margin: 0 0 24px;
  }
}
