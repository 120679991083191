.ReportForm {
    background: var(--neutral-white);
    padding: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  
    h6 {
      font-size: 28px;
      color: var(--neutral-medium);
      padding: 0 0 16px;
      font-weight: 300;
      line-height: 30px;
    }
    .desc {
      font-size: 20px;
      color: var(--neutral-dark);
      font-weight: 500;
      padding: 0 0 16px;
      line-height: 26px;
    }
    .map {
      height: 300px;
      background: lightgrey;
      margin: 0 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
    }
    .btn {
      display: flex;
      gap: 24px;
    }
    .photo {
      padding: 24px 0;
      & > span {
        font-size: 14px;
        color: var(--neutral-medium);
        font-weight: 500;
        line-height: 18px;
        margin: 0 0 12px;
        display: block;
      }
      & > .button {
        display: flex;
        gap: 16px;
      }
    }
    .link {
      .urlField {
        display: flex;
        gap: 16px;
        align-items: center;
        .width {
          width: 100%;
        }
      }
      .url {
        margin: 0 0 16px;
      }
      label {
        font-size: 14px;
        color: var(--neutral-medium);
        font-weight: 500;
        line-height: 18px;
        margin: 0 0 8px;
        display: block;
      }
      input {
        border-radius: 4px;
        border: 1px solid rgba(20, 25, 47, 0.5);
        width: 100%;
        padding: 16px 12px;
        font-size: 16px;
        color: var(--neutral-dark);
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  .flex {
    display: flex;
    gap: 24px;
    margin: 0 0 24px;
    & > div {
      flex: 1;
    }
  }

 