.listingContainer {
  grid-column: span 7;
  padding: 24px;

  .header {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    padding-bottom: 28px;
  }
  .searchCount {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 500;
    line-height: 26px;
    padding-bottom: 28px;
    .count {
      color: var(--neutral-dark);
    }
  }
  .propertyList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    overflow: auto;
    height: calc(100vh - 420px);
  }
  .label {
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--neutral-medium);
  }
  .flex {
    flex-wrap: nowrap;
    gap: 24px;
  }
}
.filter {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  .btn {
    button {
      flex: 1;
      justify-content: center;
    }
  }
  .custom {
    svg {
      path {
        stroke: var(--primary-color);
      }
    }
  }
}

.Channels {
  background: var(--neutral-white);
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(18, 38, 63, 0.16);
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.ChannelListingHolder {
  display: grid;
  grid-template-columns: 1fr 470px;
  grid-gap: 24px;
  align-items: flex-start;
}

.notificationsPannel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  & .exclusive {
    display: flex;
    align-items: center;
    gap: 10px;
    .selector {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    & span {
      font-size: 28px;
      font-style: normal;
      font-weight: 300;
    }
    & button {
      border: 1px solid #74788c;
      padding: 8px 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      height: 34px;
      background: #fff;
      & svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  & .ExclusivebuttonHolder {
    display: flex;
    gap: 20px;
    & button {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      padding: 8px 12px;
      height: 34px;
      & svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.chalneSidebar {
  border-radius: var(--Tokens-radius_md, 16px);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%),
    var(--Colors-neutral_light, #c7dce8);
  padding: 24px;
  & h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 29px;
    & svg {
      background: var(--primary-color);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      border-radius: 100%;
      padding: 10px;
    }
  }
  & ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    & li {
      display: flex;
      min-height: 54px;
      justify-content: space-between;
      align-items: center;
      & h4 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
      }
      & span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
      & button {
        border-radius: 8px;
        background: #c7dce8;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: var(--primary-color);
        height: 34px;
        line-height: 4px;
        border: none;
      }
    }
  }
  & .toggleContainer {
    & [class*='tab-list'] {
      display: flex;
      flex-direction: row;
      border: none;
      margin-bottom: -10px;
      & li {
        height: 36px;
        padding: 0px 15px;
        border: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        border-radius: 8px;
        overflow: hidden;
        min-height: inherit;
        &[class*='tab--selected'] {
          border: none;
          background: var(--neutral-light);
          &:after {
            display: none;
          }
        } /* 128.571% */
      }
    }
  }
}

.toggleContainerr {
  width: 100%;
  :global .react-tabs__tab-list {
    display: flex;
    & li {
      flex: 1 1;
      text-align: center;
    }
  }
  :global .react-tabs__tab-panel {
    padding-top: 24px;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

.center {
  justify-content: center;
}
.ChannleMemberList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 370px;
  overflow: auto;
  margin-bottom: 30px;
}

.ChanneldetailsAbout {
  border-top: 1px solid var(--neutral-light);
  flex-direction: unset !important;
  margin-top: 30px;
  padding-top: 20px;
  & .ChanneldetailsHolder {
    flex: 1;
    display: flex;
    gap: 10px;
  }
}
.shareToggle {
  border: 1px solid #14192f80;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--neutral-medium);
  opacity: 0.5;
  padding: 16px 12px;
  border-radius: 4px;
}
