.wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
.upgrade {
  grid-column: span 4;
}
.workSpaceContainer {
  background: var(--neutral-white);
  padding: 24px;
  border-radius: 16px;
  flex: 1;
  grid-column: span 8;
  h5 {
    font-size: 28px;
    font-weight: 300;
    line-height: 30px;
    color: var(--neutral-medium);
  }
  .details,
  .owner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .avatar {
    display: flex;
    gap: 24px;
    .right {
      display: flex;
      flex-direction: column;
      gap: 24px;
      p {
        font-size: 20px;
        color: var(--neutral-medium);
        font-weight: 500;
        line-height: 26px;
      }
      .btn {
        display: flex;
        gap: 24px;
      }
    }
  }
  .owner {
    padding-top: 40px;
    > p {
      font-size: 20px;
      color: var(--neutral-medium);
      font-weight: 500;
      line-height: 26px;
    }
  }

  .subscription {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid #e3e4e8;
      padding: 0 0 40px;
      margin: 0 0 24px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
    }
    p {
      font-size: 20px;
      color: var(--neutral-medium);
      font-weight: 500;
      line-height: 26px;
    }
  }
}
