.listingContainer {
  grid-column: span 7;
  padding: 24px;

  .header {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    padding-bottom: 28px;
  }
  .searchCount {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 500;
    line-height: 26px;
    padding-bottom: 28px;
    .count {
      color: var(--neutral-dark);
    }
  }
  .propertyList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    overflow: auto;
    height: calc(100vh - 500px);
  }
  .label {
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--neutral-medium);
  }
  .flex {
    flex-wrap: nowrap;
    gap: 24px;
  }
}
.filter {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  .btn {
    margin: 20px 0;
    button {
      flex: 1;
      justify-content: center;
    }
  }
  .custom {
    svg {
      path {
        stroke: var(--primary-color);
      }
    }
  }
}
