.already {
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: var(--neutral-medium);
  }
  button {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: var(--primary-color);
    background: none;
    border: none;
    padding: 0;
  }
}
