.table {
  :global .rs-pagination-group {
    margin: 0 auto;
    padding: 16px 0;
    justify-content: center;
    .rs-pagination {
      display: flex;
      button {
        display: flex;
        font-size: 14px;
        color: var(--neutral-medium);
        font-weight: 600;
        line-height: 18px;
        &.rs-pagination-btn-active {
          background: var(--primary-color);
          color: #fff;
          border-radius: 50%;
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  .header {
    color: #000;
    :global .rs-table-cell {
      border-bottom: 1px solid var(--neutral-light-gray);
      .rs-table-cell-content {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: var(--neutral-medium);
        text-transform: uppercase;
        padding: 8px;
      }
    }
  }
  .row {
    border-bottom: 1px solid var(--neutral-light-gray);
    :global .rs-table-cell-content {
      padding: 8px;
      display: flex;
      align-items: center;
      .rs-table-cell-wrap {
        font-size: 16px;
        color: var(--neutral-medium);
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
