.primary {
  background: var(--primary-color);
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  border-radius: 8px;
  padding: 16px;
  border: none;
  color: var(--neutral-white);

  transition:
    background-color 0.3s,
    box-shadow 0.3s,
    transform 0.1s;
  &:hover {
    background-color: var(--neutral-light);
    color: var(--primary-color);
    svg {
      path {
        stroke: var(--primary-color);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.5);
  }
  &[disabled] {
    background: var(--disabled);
    cursor: not-allowed;
  }
  &:active {
    background-color: #0d47a1;
  }
  &.fullWidth {
    width: 100%;
  }
  &.noBorder {
    border: none;
  }

  &.noBackground {
    background-color: transparent;
    &:hover {
      color: var(--primary-color) !important;
    }
  }
  &.icon {
    display: flex;
    align-items: center;
    span {
      margin-right: 8px;
      display: flex;
      &.rightIcon {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}
