.tabContainer {
  color: #000;
  ul {
    border-bottom-color: var(--neutral-light);
    :global li.react-tabs__tab {
      border: none;
      border-bottom: 1px solid var(--neutral-medium);
      bottom: -1px;
      padding: 16px 24px;
      text-transform: uppercase;
      color: var(--neutral-medium);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      &.react-tabs__tab--selected {
        color: var(--primary-color);
        &:after {
          content: '';
          height: 4px;
          width: 100%;
          background: var(--primary-color);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }
  :global .react-tabs__tab-panel {
    padding: 40px 0 0;
  }
}
