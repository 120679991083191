.detailContainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  align-items: flex-start;

  .card {
    grid-column: span 8;
    gap: 24px;
    display: grid;
    .toggle {
      border-radius: 16px;
      background: var(--neutral-white);
      padding: 24px;
      box-shadow: 0px 4px 8px 0px rgba(18, 38, 63, 0.16);
      gap: 24px;
      display: grid;
      h5 {
        font-size: 28px;
        color: var(--neutral-medium);
        font-weight: 300;
        line-height: 30px;
      }
      .toggleby {
        display: flex;
        align-items: center;
        gap: 8px;
        h5 {
          font-size: 16px;
          color: var(--neutral-dark);
          font-weight: 500;
          line-height: 24px;
        }
        h6 {
          font-size: 14px;
          color: var(--neutral-medium);
          font-weight: 500;
          line-height: 18px;
        }
      }
    }
    .action {
      display: flex;
      gap: 8px;
      align-items: center;
    }
    .delete {
      path {
        stroke: var(--neutral-medium);
      }
    }
  }
  .right {
    grid-column: span 4;
    gap: 24px;
    display: grid;
    .todoList {
      background: var(--neutral-white);
      padding: 24px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      h5 {
        font-size: 24px;
        color: var(--neutral-medium);
        font-weight: 300;
        line-height: 30px;
      }
    }
    .links {
      gap: 24px;
      display: flex;
      flex-flow: column;
      a {
        display: flex;
        font-size: 16px;
        color: var(--neutral-medium);
        font-weight: 500;
        line-height: 24px;
        text-decoration-line: underline;
      }
    }
    .sideContent {
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      gap: 24px;
      border-radius: 16px;
      background: var(--neutral-light);
      padding: 24px;
      h5 {
        gap: 10px;
        display: flex;
        align-items: center;
        font-size: 28px;
        color: var(--neutral-medium);
        font-weight: 300;
        line-height: 30px;
        svg {
          background: var(--primary-color);
          width: 40px;
          height: 40px;
          padding: 8px;
          border-radius: 30px;
        }
      }
      ul {
        padding-left: 20px;
        list-style: disc;
      }
    }
  }
}

.create {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .btn {
    justify-content: space-between;
    gap: 16px;
    button {
      flex: 1;
    }
  }
}
