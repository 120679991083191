.badge {
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  padding: 8px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: var(--neutral-white);
}

.badgeIcon {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.primary {
  background-color: var(--primary-color);
}

.success {
  background-color: var(--success);
}

.info {
  background-color: var(--info-color);
}

.error {
  background-color: red;
}
