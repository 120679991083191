.card {
  border-radius: 16px;
  border: 1px solid var(--neutral-light-gray);
  background: var(--neutral-white);
  box-shadow: 0px 1px 2px 0px rgba(18, 38, 63, 0.16);
  position: relative;
  overflow: hidden;
  .heartIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--neutral-white);
    box-shadow: 0px 2px 4px 0px rgba(18, 38, 63, 0.16);
    padding: 8px 12px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
  }
  .propertyDetails {
    padding: 16px;
    h3 {
      font-size: 24px;
      font-weight: 300;
      line-height: 30px;
      color: var(--neutral-dark);
      padding: 0 0 8px;
      min-height: 80px;
    }
    .address {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: var(--neutral-medium);
      padding-bottom: 12px;
      svg {
        width: 16px;
        path {
          stroke: var(--neutral-medium);
        }
      }
    }
    .bedBathSqft {
      display: flex;
      gap: 16px;
      border-bottom: 1px solid rgba(20, 25, 47, 0.5);
      margin: 0 0 16px;
      padding: 0 0 16px;
      span {
        font-size: 16px;
        color: var(--neutral-medium);
        font-weight: 300;
        line-height: 22px;
      }
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      color: var(--neutral-medium);
      font-weight: 300;
      line-height: 26px;
    }
  }
}
.detailCard {
  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .bedBathSqft {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
  }
  .propertyDetails {
    h3 {
      min-height: auto;
    }
  }
  .price {
    span {
      strong {
        font-weight: 300;
        color: var(--neutral-dark);
      }
    }
  }
}

.horizontol {
  display: flex;

  img {
    height: 300px;
    width: 100%;
  }
  .propertyDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
