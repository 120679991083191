.formControl {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.label {
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--neutral-medium);
  font-weight: 500;
  line-height: 18px;
  display: block;
}

.inputWrapper {
  position: relative;
}

.inputIcon {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.inputField {
  width: 100%;
  padding: 16px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #666;
  }
  &.errorInput {
    border-color: #ff3860;
  }
  &.withIcon {
    padding-left: 30px;
  }
  &.rightInput {
    padding-left: 12px;
    padding-right: 35px;
  }
}

.error {
  color: #ff3860;
  font-size: 0.75rem;
  margin-top: 4px;
}

.helperText {
  font-size: 0.75rem;
  margin-top: 4px;
  color: #666;
}

.rightIcon {
  right: 15px;
  left: auto;
}
