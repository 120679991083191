.formControl {
  width: 100%;
  .label {
    color: var(--neutral-medium);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 8px;
    display: block;
  }
  .dropdownInput {
    border-radius: 4px;
    border: 1px solid var(--border-color);
    padding: 14px 12px;
    color: var(--neutral-medium);
    font-size: 16px;
    font-weight: 500;
    :global .react-dropdown-select-content {
      input {
        width: 90%;
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        &::placeholder {
          color: var(--placeholder);
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  .dropdownContainer {
    position: relative;
    .inputIcon {
      padding-left: 40px;
    }
    .icon {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      svg {
        width: 16px;
        path {
          stroke: var(--neutral-medium);
        }
      }
    }
  }
}
