.upload {
  background: var(--neutral-light);
  font-size: 14px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;
  cursor: pointer;
  color: var(--primary-color);
  display: inline-flex;
}
