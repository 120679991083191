.chatHolder{ 
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 8px 0px rgba(18, 38, 63, 0.16);
    height: calc(100vh - 170px);
    display: flex;
    flex-direction: column;
    & .chatHolderHeader{
        min-height: 100px;
        border-bottom:1px solid var(--neutral-light-gray);
        display: grid;
        grid-template-columns: repeat(2,1fr);
        align-items: center;
        padding:16px;
        & .chatHolderHeaderLeft{
            display: flex;
            flex-direction: column;
            gap:5px;
            & h6{
                font-size: 14px;
                font-style: normal; 
                font-weight: 600;
                line-height: 18px; /* 128.571% */
                text-transform: uppercase;
                color:var(--neutral-medium);
            }
            & .profilePic{
                display: flex;
                align-items:center;
                gap:16px;
                & .profileDp{
                    width:40px;
                    height:40px;
                    background:var(--neutral-medium);
                    border-radius: 100%;
                    overflow: hidden;
                    & img{
                        width:100%;
                        height:100%;
                        object-fit: cover;
                    }
                }
                & .profileName{
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 300;
                    display: flex;
                    align-items:center;
                }
            }
        }
    }

    & .chatAi{
        padding: 16px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;
        overflow: auto;
        & .chatMessage{
            gap:10px;
            display: flex; 
            justify-content: flex-start;
            align-items: flex-start;
        }
        & .chatMessageTime{
            font-size:12px;
            font-weight: 500;
            color:var(--neutral-medium);
        }
        & .chaAiLeft{
            gap:10px;
            display: flex;
            flex-direction: column;
        } 
        & .ChatprofileDp{
            width:40px;
            height:40px;
            flex:0 0 40px;
            background:var(--neutral-medium);
            border-radius: 100%;
            overflow: hidden;
            display: inline-flex;
            left:0px;
            top:0px;
            
            & img{
                width:100%;
                height:100%;
                object-fit: cover;
            }
        }
        & .chatMessagedetails{
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            border-radius: 0px 16px 16px 16px;
            background:var(--neutral-light);
            padding:10px;
            max-width: 448px;
        }
        & .chatMessageTag{
            display: flex;
            gap:8px;
            flex-direction: column;
            padding-left: 50px;
            & .chatMessageButton{
                display: flex;
                gap:8px;
                & button{
                    height:34px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    color:#fff;
                    padding: 0 13px;
                    &:hover{
                        color:var(--neutral-dark);
                    }
                }
            }
            
        }
       
    }

}

.chaAiRight{
    margin-left: auto;
    & .chatMessage{
        flex-direction: row-reverse;
    }
    &  .chatMessageTag{
        justify-content: flex-end;
        align-items: flex-end;
    }
} 

.chatHolderBottom{
    padding:16px; 
    border-top:1px solid var(--neutral-light-gray);
    & input{
        border-radius: 4px !important;
        height:56px;
        border:1px solid var(--neutral-light-gray) !important;
    }
}
.chatHolderHeaderSearch{
    & input{
        border-radius: 60px !important;
        height:56px;
        border:1px solid var(--neutral-light-gray) !important;
        font-weight: 300 !important;
        color:var(--neutral-light-gray);
    }
}

.AiPerson{
    padding:0 24px;
    display: flex;
    gap:8px;
    & .profileDp{
        width:40px;
        height:40px;
        flex:0 0 40px;
        background:var(--neutral-medium);
        border-radius: 100%;
        overflow: hidden;
        display: inline-flex;
        & img{
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }
    & .AiPersonDes{
        display: flex; 
        gap:0px;
        flex-direction: column;
        & h6{
            font-size: 16px;
            font-weight: 500;
            color:var(--neutral-dark);
        }
        & p{
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color:var(--neutral-medium)
        }
    }
}