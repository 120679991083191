img {
  max-width: 100%;
}

* {
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
}
p {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #06417e;
  --neutral-medium: #74788c;
  --border-color: #c4c5cb;
  --neutral-white: #ffffff;
  --error: #ff0000;
  --disabled: #6999ca;
  --placeholder: #b9bbc5;
  --info-color: #3d7291;
  --background-one: #e1e8f0;
  --neutral-offwhite: #f7f7f7;
  --neutral-dark: #14192f;
  --neutral-light: #c7dce8;
  --warning: #e0c808;
  --neutral-light-gray: #e2e3e7;
  --success: #00a652;
}

button {
  cursor: pointer;
}
button:disabled {
  cursor: default;
}

.error {
  padding-top: 5px;
  font-size: 12px;
  font-weight: 400;
  color: var(--error);
}

.helperText {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding-top: 8px;
  color: var(--info-color);
}

form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .inlinefields {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: flex-start;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.divider {
  margin: 0;
  background: var(--neutral-medium);
  opacity: 0.2;
  width: 100%;
}

.page-header {
  display: flex;
  gap: 16px;
  align-items: center;
  h5 {
    font-size: 40px;
    color: var(--neutral-dark);
    font-weight: 300;
    line-height: 48px;
  }
  .shadow-less {
    box-shadow: none;
  }
  button {
    box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.16);
  }
}

.globalSideContentHolder {
  display: grid;
  grid-template-columns: 1fr 470px;
  grid-gap: 24px;
  align-items: flex-start;
}

.pac-container {
  background-color: white; /* Background color */
  border: 1px solid #ccc; /* Border */
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow */
  font-family: Arial, sans-serif; /* Font family */
  max-height: 200px; /* Maximum height of the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 8px 0; /* Padding */
  z-index: 1000; /* Ensure dropdown appears on top of other elements */
}

.pac-item {
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 6px 12px; /* Padding */
  font-size: 16px;
}

.pac-item:hover {
  background-color: #f5f5f5; /* Background color on hover */
}

.pac-item-selected {
  background-color: #f0f0f0; /* Background color of selected item */
}

.pac-item-query {
  font-weight: bold; /* Bold font for query match */
  font-size: 16px;
}
