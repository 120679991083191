.secondary {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;

  &.noBorder {
    border: none;
  }

  &.noBackground {
    background-color: transparent;
  }
  &.icon {
    display: flex;
    align-items: center;
    span {
      margin-right: 8px;
      display: flex;
      &.rightIcon {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}
