.rangeSlider {
  label {
    font-size: 14px;
    color: var(--neutral-medium);
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    padding: 0 0 8px;
    display: inline-flex;
  }
  :global .rs-slider {
    padding-bottom: 8px;
  }
  :global .rs-slider-bar {
    height: 30px;
    background: var(--neutral-light);
    border-radius: 20px;
  }
  :global .rs-slider-progress-bar {
    background: var(--primary-color);
    border-radius: 20px;
    height: 30px;
    border: 2px solid var(--neutral-light);
  }
  :global .rs-slider-handle {
    background: var(--neutral-white);
    width: 24px;
    height: 24px;
    border-radius: 50px;
    &[data-key='start'] {
      top: 3px;
      margin-left: 3px;
    }
    &[data-key='end'] {
      margin-left: -27px;
      top: 3px;
    }
    &:before {
      background: var(--neutral-light-gray);
      margin-left: 6px;
      top: 6px;
      border: none;
    }
  }

  .icon {
    background: none;
    padding-right: 0;
    font-size: 16px;
    color: var(--neutral-medium);
    font-weight: 500;
    line-height: 24px;
  }
  .flex {
    flex-wrap: nowrap;
    gap: 8px;
  }
  :global .rs-input-group {
    border-radius: 4px;
    border: 1px solid rgba(20, 25, 47, 0.5);
    background: var(--neutral-white);
    input {
      padding: 12px 16px;
      font-size: 16px;
      color: var(--neutral-medium);
      font-weight: 500;
      line-height: 24px;
    }
  }
}
