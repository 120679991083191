.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  padding: 50px 0 0;
  h2 {
    margin-right: auto;
    font-size: 40px;
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 48px;
  }
  .upgrade {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    color: var(--neutral-light);
  }
  .profileInfo {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--neutral-dark);
    }
    span {
      font-size: 14px;
      color: var(--neutral-medium);
      font-weight: 500;
      line-height: 18px;
    }
  }
}

.profileMenu {
  border-radius: 16px;
  display: inline-block;
  position: absolute !important;
  right: 5%;
  top: 110px;
  width: 292px !important;
  overflow: hidden;
  .userInfo {
    background: grey !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
    padding: 40px 0;
    p {
      color: var(--neutral-white);
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
    }
    span {
      color: var(--neutral-white);
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .profileLink {
    background: var(--neutral-dark);
    padding: 8px 24px;
    .list {
      display: flex;
      flex-direction: column;
      a {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        color: var(--neutral-white);
        text-decoration: none;
        justify-content: space-between;
        padding: 16px;
        span {
          display: flex;
          align-items: center;
          svg {
            margin: 0 8px 0 0;
          }
        }
        &:last-child {
          margin-top: 10px;
          border-top: 2px solid rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
