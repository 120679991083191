.selectorModal {
  top: 200px;
  left: 396px;
  position: absolute !important;
}
.channelSelector {
  background: var(--neutral-dark);
  padding: 24px;
  h4 {
    font-size: 28px;
    font-weight: 300;
    padding-bottom: 16px;
    color: var(--neutral-medium);
  }
  input {
    background: none;
    border-radius: 50px;
    transition: 0.3s;
    font-size: 20px;
    font-weight: 300;
    padding-left: 50px !important;
    border: none;
    background: rgba(255, 255, 255, 0.05);
    &:focus {
      background-color: var(--neutral-white);
      transition: 0.3s;
    }
  }
  .list {
    padding-top: 24px;
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    li {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;
      p {
        font-size: 300;
        font-size: 24px;
        color: var(--neutral-white);
        padding-left: 16px;
      }
      :global .rs-badge {
        margin-left: auto;
        border-radius: 8px;
        padding: 4px 8px;
        font-size: 16px;
        font-weight: 700;
        color: var(--primary-color);
      }
      &.selected {
        background: var(--primary-color);
      }
      &:hover {
        background: #82a0be;
      }
    }
  }
}
