.card {
  display: grid;
  grid-template-columns: repeat(1,1fr); 
   
  position: relative;
  overflow: hidden;
  & .kudos{
    display: flex;
    align-items:center;
    margin-top: 15px;
    gap:10px;
  }
  & .cardtopArea{ 
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
    & > div{
      display: flex;
      justify-content: space-between;
      align-items:center;
      & .profileIholder{ 
        display: flex;
        align-items:center;
        gap:10px;
      }
      & .profileIImage{
        width: 48px;
        height: 48px;
        border-radius:100%;
        display: inline-block;
        overflow: hidden;
        & img{
          width:100%;
          height: 100%;
          object-fit: cover;
          
        }
      }
    }
  }
  & .carddecHolder{
    display: grid;
    grid-template-columns: repeat(2,1fr); 
     
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    border: 1px solid var(--neutral-light-gray);
  }
  & .propertyImage{
    height: 240px;
    width: 100%;
    object-fit: cover;
  }

  & .cardBottom{
    background:var(--primary-color);
    grid-column: 1/-1;
    padding:16px;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    color:#fff;
    gap:5px;
    display: flex;
    flex-direction: column;
    border-radius:0 0 20px 20px;
    & .topLine{
      display: flex; 
      justify-content: space-between;
      & span{ 
        display: flex;
        gap:10px;
      }
    }
  }

  .heartIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--neutral-white);
    box-shadow: 0px 2px 4px 0px rgba(18, 38, 63, 0.16);
    padding: 8px 12px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
  }
  .propertyDetails {
    padding: 16px;
    
    h3 {
      font-size: 24px;
      font-weight: 300;
      line-height: 30px;
      color: var(--neutral-dark);
      padding: 0 0 8px;
      min-height: 80px;
    }
    .address {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: var(--neutral-medium);
      padding-bottom: 12px;
      svg {
        width: 16px;
        path {
          stroke: var(--neutral-medium);
        }
      }
    }
    .bedBathSqft {
      display: flex;
      gap: 16px;
      border-bottom: 1px solid rgba(20, 25, 47, 0.5);
      margin: 0 0 16px;
      padding: 0 0 16px;
      span {
        font-size: 16px;
        color: var(--neutral-medium);
        font-weight: 300;
        line-height: 22px;
      }
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      color: var(--neutral-medium);
      font-weight: 300;
      line-height: 26px;
    }
  }
}
.detailCard {
  border:10px solid red !important; 
  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .bedBathSqft {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
  }
  .propertyDetails {
    border:2px solid red !important;
    h3 {
      min-height: auto;
    }
  }
  .price {
    span {
      strong {
        font-weight: 300;
        color: var(--neutral-dark);
      }
    }
  }
}

.horizontol {
  display: flex;

  img {
    height: 300px;
    width: 100%;
  }
  .propertyDetails {
    border:2px solid red !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
