.formControl {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .label {
    color: var(--neutral-medium);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 8px;
  }
  .inputWrapper {
    position: relative;
    .inputIcon {
      position: absolute;
      left: 10px; // Adjust as necessary
      top: 50%;
      transform: translateY(-50%);
      // Style your icon as necessary
    }
    input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid var(--border-color);
      padding: 16px 12px;
      &.errorInput {
        border: 1px solid var(--error);
      }
      &.password {
        padding-right: 40px;
      }
    }
    .withIcon {
      padding-left: 40px;
    }

    .eyeIcon {
      position: absolute;
      right: 10px;
      bottom: 12px;
      border: none;
      cursor: pointer;
      background: none;
    }
  }
}
