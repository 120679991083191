.linkPrimary {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-decoration-line: underline;
  color: var(--neutral-medium);
  text-decoration-color: var(--neutral-medium);
}

.active {
  color: var(--primary-color);
  svg {
    path {
      stroke: var(--primary-color);
    }
  }
}
