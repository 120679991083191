.create {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(12, 1fr);
  align-items: flex-start;
  .left {
    grid-column: span 8;
    background: var(--neutral-white);
    border-radius: 16px;
    padding: 24px;
    .header {
      display: flex;
      gap: 16px;
      align-items: center;
      h5 {
        font-size: 28px;
        color: var(--neutral-medium);
        font-weight: 300;
        line-height: 30px;
      }
    }
    .tab {
      padding-top: 24px;
      :global .react-tabs__tab-list {
        background: var(--neutral-light);
        display: inline-flex;
        border-radius: 8px;
        overflow: hidden;
        .react-tabs__tab {
          border: none;
          border-radius: 0;
          color: var(--primary-color);
          padding: 16px;
          &.react-tabs__tab--selected {
            background: var(--primary-color);
            color: var(--neutral-white);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .right {
    grid-column: span 4;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        color: var(--neutral-white);
      }
      .edit {
        padding: 0;
      }
    }
    .selectedProperty {
      background: var(--neutral-dark);
      padding: 16px;
      border-radius: 16px 16px 0 0;
      gap: 16px;
      display: grid;
    }

    .address {
      p {
        font-size: 24px;
        color: var(--neutral-white);
        font-weight: 300;
        line-height: 30px;
      }
    }
    .est {
      background: var(--primary-color);
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      h5 {
        color: var(--neutral-white);
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
      }
      p {
        font-size: 28px;
        color: var(--neutral-white);
        font-weight: 300;
        line-height: 30px;
        margin: 0;
        &.rank {
          color: var(--neutral-light);
        }
      }
    }
    .details {
      background: var(--neutral-dark);
      border-radius: 0 0 16px 16px;
      padding: 16px;
      display: grid;
      gap: 16px;
      border-bottom: 1px solid #000;
      &.noRadius {
        border-radius: 0;
      }
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h5 {
            color: var(--neutral-white);
            font-size: 24px;
            font-weight: 300;
            line-height: 30px;
          }
          p {
            font-size: 28px;
            color: var(--neutral-white);
            font-weight: 300;
            line-height: 30px;
            margin: 0;
            &.rank {
              color: var(--neutral-light);
            }
          }
        }
      }
    }
  }
}

.mode {
  display: grid;
  gap: 24px;
  .filter {
    display: grid;
    gap: 24px;
  }
  h5 {
    font-size: 24px;
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 30px;
  }
  p {
    font-size: 16px;
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 24px;
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      font-size: 16px;
      padding: 16px 24px;
    }
  }
}
.btn {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  button {
    &:last-child {
      margin-left: auto;
    }
  }
}
.range {
  position: relative;

  :global .rs-slider {
    padding-bottom: 8px;
  }
  :global .rs-slider-bar {
    height: 30px;
    background: var(--neutral-light);
    border-radius: 20px;
  }
  :global .rs-slider-progress-bar {
    background: var(--primary-color);
    border-radius: 20px;
    height: 30px;
  }
  :global .rs-slider-handle {
    background: var(--neutral-white);
    width: 24px;
    height: 24px;
    border-radius: 50px;
    top: 8%;
    margin-left: -27px;
    &[style='left: 0%;'] {
      margin-left: 1px;
    }
    input {
      margin-left: -27px;
      &[aria-valuenow='0'] {
        margin-left: 0;
      }
    }
    &:before {
      background: var(--neutral-light-gray);
      margin-left: 6px;
      top: 6px;
      border: none;
    }
  }
}

.scaleMarkings {
  display: flex;
  justify-content: space-between;
  width: 99%;
  margin: 0 auto;
}

.scaleMark {
  width: 1px;
  height: 4px;
  background-color: var(--neutral-medium);
}

.labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  position: relative;
}

.label {
  position: relative;
  font-size: 14px;
  color: var(--neutral-medium);
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}
