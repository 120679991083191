.properties {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: var(--neutral-white);
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(18, 38, 63, 0.16);
  overflow: hidden;
  height: calc(100vh - 210px);
  .map {
    grid-column: span 5;
    background: lightgray;
  }
}
