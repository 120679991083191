.formControl {
  width: 100%;
  .label {
    color: var(--neutral-medium);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 8px;
    display: block;
  }
  .dropdownInput {
    border-radius: 4px;
    width: 100%;
    border: 1px solid var(--border-color);
    padding: 26px 12px;
    color: var(--neutral-medium);
    font-size: 16px;
    font-weight: 500;
    :global {
      button {
        width: 90%;
        position: absolute;
        background: transparent;
        top: 0;
        bottom: 0;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &::placeholder {
          color: var(--placeholder);
          font-size: 16px;
          font-weight: 400;
        }
      }
      ul {
        padding: 0;
      }
    }
  }

  .dropdownContainer {
    position: relative;
    .inputIcon {
      padding-left: 40px;
    }
    .icon {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      svg {
        width: 16px;
        path {
          stroke: var(--neutral-medium);
        }
      }
    }
  }
}

.datepicker {
  label {
    font-size: 14px;
    color: var(--neutral-medium);
    margin: 0 0 8px;
    font-weight: 500;
    line-height: 18px;
    display: block;
  }
  :global .rs-input-group {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 8px 12px;
    .rs-input {
      padding: 0;
    }
    .rs-input-group-addon {
      padding-right: 0 !important;
      min-width: auto !important;
    }
  }
}
