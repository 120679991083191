.container {
  height: 100vh;
}

.authWrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  @media (max-width: 1024px) {
    height: 100vh;
  }
  .left {
    grid-column: span 7;
    @media (max-width: 1024px) {
      display: none;
    }
    span img {
      height: 100vh;
      padding: 25px 24px;
      width: 100%;
    }
  }
  .right {
    grid-column: span 5;
    gap: 32px;
    display: grid;
    width: 70%;
    margin: 0 auto;
    @media (max-width: 1024px) {
      grid-column: span 12;
    }
  }
}
