.detailWrap {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  align-items: flex-start;
  .left {
    grid-column: span 8;
    background: var(--neutral-dark);
    padding: 24px;
    border-radius: 16px;
    display: grid;
    gap: 24px;
    h5 {
      font-size: 28px;
      color: var(--neutral-white);
      font-weight: 300;
      line-height: 30px;
    }
    .estValue {
      background: var(--primary-color);
      border-radius: 8px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > div {
        display: flex;
        justify-content: space-between;
        & > div {
          display: flex;
          gap: 24px;
          align-items: center;
        }
      }
      h4,
      p {
        font-size: 24px;
        color: var(--neutral-light);
        font-weight: 300;
        line-height: 30px;
        margin: 0;
      }
    }
    table {
      overflow-x: auto;
      thead {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }
      th {
        text-align: left;
        font-size: 14px;
        color: var(--neutral-white);
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
        padding: 8px;
      }
      tr {
        td {
          padding: 16px 0 0;
          font-size: 24px;
          font-weight: 300;
          line-height: 30px;
          color: var(--neutral-white);
          &:first-child {
            width: 70%;
          }
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        .togglebtn {
          svg {
            path {
              stroke: #fff;
            }
          }
          &:hover {
            svg {
              path {
                stroke: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }
  .right {
    grid-column: span 4;
    border-radius: 16px;
    background: var(--neutral-white);
    padding: 24px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
    h5 {
      font-size: 24px;
      color: var(--neutral-medium);
      font-weight: 300;
      line-height: 30px;
    }
    .moreDetail {
      display: grid;
      gap: 24px;
    }
    .status {
      & > p {
        display: flex;
        gap: 8px;
        font-size: 14px;
        color: var(--neutral-medium);
        font-weight: 500;
        line-height: 18px;
        span {
          display: flex;
          gap: 8px;
          p {
            font-weight: 500;
            color: var(--neutral-medium);
            font-size: 16px;
          }
        }
      }
    }
    .sharedStatus {
      li {
        display: flex;
        align-items: center;
        gap: 16px;
        svg {
          path {
            stroke: var(--neutral-medium);
          }
        }
      }
    }
  }
}
