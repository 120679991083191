.pricingPlanDialog {
  padding: 24px;
  background: #fff;
  border-radius: 16px;

  h2 {
    font-size: 28px;
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 30px;
  }
  .planList {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    .plan {
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 4px 8px 0px rgba(18, 38, 63, 0.16);
      .content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .feat {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: var(--neutral-medium);
          span {
            color: var(--primary-color);
            text-transform: uppercase;
          }
        }
        h3 {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          text-transform: uppercase;
          color: var(--primary-color);
        }
        .price {
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          color: var(--neutral-medium);
          display: inline-flex;
          gap: 8px;
          align-items: center;
          .currency {
            font-size: 20px;
            line-height: 26px;
          }
          span {
            font-size: 40px;
            color: var(--primary-color);
            line-height: 48px;
          }
        }
        .description {
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          color: var(--neutral-medium);
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 10px;
          li {
            display: flex;
            gap: 8px;
            align-items: center;
            font-size: 16px;
            color: var(--neutral-medium);
            font-weight: 500;
            line-height: 24px;
            path {
              stroke: var(--neutral-medium);
            }
          }
        }
      }
      .subtitle {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.9) 100%
          ),
          var(--neutral-medium);
        font-size: 14px;
        color: var(--neutral-medium);
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        padding: 8px 0;
      }
    }
  }
}
