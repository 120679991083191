.createChannel {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.btn {
  justify-content: space-between;
  gap: 16px;
  button {
    flex: 1;
  }
}
