.sideBar {
  background: var(--background-one);
  display: inline-block;
  padding: 56px 48px;
  position: relative;
  max-width: 320px;
  width: 100%;
  transition: 0.6s;
  &.short {
    padding: 56px 22px;
    max-width: 90px;
    transition: 0.6s;

    .workspaceToggle {
      text-align: center;
      :global .details {
        & > button {
          justify-content: center;
        }
      }
    }
    .navLinks {
      li {
        a {
          span {
            opacity: 0;
            visibility: hidden;
            width: 0;
            height: 0;
            flex: 0;
            transition: 0.6s;
            position: absolute;
          }
        }
      }
    }
  }
  span.logo {
    img {
      display: flex;
      margin: 0 auto;
    }
  }
  .toggleBtn {
    position: absolute;
    border: none;
    right: -15px;
    box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.16);
    background: var(--neutral-white);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.off {
      transform: rotate(180deg);
      box-shadow: 0px -10px 20px 0px rgba(18, 38, 63, 0.16);
    }
  }
  .workspaceToggle {
    margin-top: 40px;
    border-radius: 8px;
    background: var(--neutral-white);
    padding: 8px;
    p.title {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-transform: uppercase;
      color: var(--neutral-medium);
    }
  }
}

body :global .details {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > button {
    display: flex;
    background: none;
    border: none;
    text-align: left;
    padding: 0;
    margin: 0;
    span {
      padding: 0 18px;
      flex: 1;
      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: var(--neutral-medium);
      }
    }
    h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      color: var(--primary-color);
    }
    p {
      color: var(--neutral-medium);
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
  }
}

body :global .workspaceModal {
  background: #fff;
  position: absolute;
  top: 150px;
  left: 50px;
  width: 292px;
  border-radius: 16px;

  box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.16);
  .details {
    padding: 24px;
    & > button {
      h6 {
        background: var(--neutral-offwhite);
        border-radius: 13px;
        font-size: 33px;
        font-weight: 700;
        line-height: 43px;
        box-shadow: 0px 1.2px 2.4px 0px rgba(18, 38, 63, 0.16);
        display: flex;
        width: 43px;
        align-items: center;
        justify-content: center;
        height: 43px;
      }
      p {
        font-weight: 500;
        color: var(--neutral-dark);
      }
    }
  }
  a {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: var(--neutral-medium);
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    svg {
      stroke: var(--neutral-medium);
    }
  }
  .invite {
    padding: 16px 24px;
  }
  .new-workspace {
    border-top: 1px solid var(--neutral-medium);
    padding: 16px 24px;
    .delete {
      a {
        color: var(--error);
      }
      svg {
        stroke: var(--error);
      }
    }
  }
}

body :global .management {
  background: var(--neutral-dark);
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--neutral-light);
  }
  a {
    color: var(--neutral-white);
    svg {
      stroke: var(--neutral-white);
    }
  }
}

.navLinks {
  padding-top: 4vw;
  li {
    padding: 16px 8px;
    a {
      display: flex;
      align-items: center;
      gap: 16px;
      text-decoration: none;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      color: var(--neutral-medium);
      .off {
        display: none;
      }
      &.active {
        color: var(--primary-color);
      }
    }
  }
}

.mobileNav {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
  .sideBar {
    max-width: 100%;
  }
}

.deleteWorkspaceFormModel, .deleteWorkspaceBtnsProfile {
  display: flex;
  flex-direction: column;
  gap: 24px;
  // padding: 24px;
}
.deleteWorkspaceBtnsProfile {
  gap: 12px;
  // padding: 24px;
}

.worknavModal .body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}


