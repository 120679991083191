.progressbar{
	border-radius: 8px;
    height: 8px;
    background: var(--neutral-light-gray);
    display: flex;
    overflow: hidden;
	margin-bottom: 24px;
  & span{
    background:var(--primary-color);
  }
}

.ChannelListingHolder {
	background:var(--neutral-light);
	padding:16px;
	border-radius:16px;
	& h2{
		font-size: 28px;
		font-style: normal;
		font-weight: 300;
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 29px;
		& svg{
			background: var(--primary-color);
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			border-radius: 100%;
			padding: 10px;
		}
	}
	& p{
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; 
		color:var(--neutral-medium);
		margin-bottom: 24px;
	}
}