.formBtns {
  border-top: 1px solid #e3e4e8;
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  padding-top: 24px;
  .leftBtn {
    display: flex;
    gap: 16px;
  }
}
