.checkboxContainer {
  display: flex;
  align-items: center;
  .customCheckbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: var(--neutral-medium);
      flex: 1;
    }
  }
  .hiddenCheckbox {
    display: none;
    &:checked {
      + {
        .styledCheckbox {
          background-color: #6c757d;
          border-color: transparent;
          i {
            visibility: visible;
          }
        }
      }
    }
  }
  .styledCheckbox {
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    transition: background-color 150ms;
    i {
      color: white;
      visibility: hidden;
    }
  }
}
