.datepicker {
  label {
    font-size: 14px;
    color: var(--neutral-medium);
    margin: 0 0 8px;
    font-weight: 500;
    line-height: 18px;
    display: block;
  }
  :global .rs-input-group {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 8px 12px;
    .rs-input {
      padding: 0;
    }
    .rs-input-group-addon {
      padding-right: 0 !important;
      min-width: auto !important;
    }
  }
}
