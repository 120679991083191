.overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  :global .rs-modal-content {
    padding: 0;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: none;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0;
  h4 {
    font-size: 28px;
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 30px;
  }
  svg {
    cursor: pointer;
  }
}
