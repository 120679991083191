.upgradePlan {
  width: 100%;
  // margin-left: 24px;
  border-radius: 16px;
  background: var(--neutral-dark);
  overflow: hidden;
  img {
    height: 200px;
    object-fit: cover;
    width: 100%;
  }
  .content {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    p:first-child {
      color: var(--warning);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      padding: 0 0 8px;
    }
    p:last-child {
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      opacity: 0.6;
      color: #fff;
    }
    button {
      border-radius: 8px;
      border: none;
    }
  }
}
