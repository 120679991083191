.searchBarContainer {
  flex: 1;
  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: var(--neutral-medium);
    padding: 0 0 8px;
    display: flex;
  }
  .searchInput {
    position: relative;
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 12px;
      height: 100%;
    }
    input {
      border-radius: 4px;
      border: 1px solid rgba(20, 25, 47, 0.5);
      background: var(--neutral-white);
      padding: 16px 32px;
      font-size: 16px;
      color: var(--neutral-medium);
      font-weight: 500;
      line-height: 24px;
      width: 100%;
    }
  }
  .resultsList {
    margin-top: 4px;
    border-radius: 16px;
    border: 1px solid var(--neutral-light-gray);
    background: var(--neutral-white);
    box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.16);
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 8px;
    position: absolute;
    z-index: 11;
    .resultItem {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
