.emailTab {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 24px;
  .btn {
    display: flex;
    gap: 16px;
    button {
      flex: 1;
      text-align: center;
      justify-content: center;
    }
  }
}
