.search {
  background: url('../../assets/dashboard-ban.png');
  background-size: cover;
  padding: 20px 48px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: span 8;
  & > * {
    flex: unset;
  }
  h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 8px;
    color: var(--neutral-white);
  }
  h5 {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 24px;
    color: var(--neutral-white);
    span {
      text-decoration-line: underline;
      color: var(--warning);
    }
  }
  svg {
    width: 24px;
  }
  input {
    border-radius: 30px !important;
    padding-left: 50px !important;
  }
}

.searchWrap {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 26px;
}

.upgrade {
  grid-column: span 4;
}

.dashboard {
  display: grid;
  gap: 24px;
}

.action {
  display: flex;
  align-items: center;
  gap: 16px;
  .delete {
    padding: 8px 12px;
    span {
      margin: 0;
    }
    svg {
      path {
        stroke: var(--neutral-medium);
      }
    }
  }
}

.property {
  display: flex;
  align-items: center;
  gap: 16px;
  img {
    width: 64px;
  }
  h5 {
    font-size: 16px;
    color: var(--neutral-dark);
    font-weight: 500;
    line-height: 24px;
  }
}

.new {
  background: var(--neutral-white);
  border-radius: 16px;
  padding: 24px;
  h2 {
    font-size: 32px;
    color: var(--neutral-medium);
    font-weight: 300;
    line-height: 38px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--neutral-light-gray);
  }
  :global .react-tabs__tab-panel {
    padding-top: 10px;
  }
}
